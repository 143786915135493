.Chord {
   width: 100%;
   height: 100%;
   margin: 10% auto;
   display: inline;
}

.Chord-Name {
   font-size: 3em;
}

.fingering {
   display: block;
   margin: 0;
   padding: 0;
}
