.Jam-Chords {
   margin: 5% auto;
   box-shadow: 4px 3px 8px;
   width: 80%;
   background-color: white;
   border-radius: 2%;
}

.Jam-Btn {
   margin-top: 2em;
   width: 10em;
   margin: 2%;
}

.display-2 {
   margin-bottom: .75em;
}

.Loading {
   font-size: 3em;
   margin: 20%;
}

.Jam-P {
   width: 60%;
   margin: 5% auto 5% auto;
   box-shadow: 4px 3px 8px;
   min-width: 200px;
   font-size: 1.5vw;
   padding: 10px;
}

.Jam {
   background: hsla(196, 100%, 83%, 1);

   background: linear-gradient( hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   background: -moz-linear-gradient(hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   background: -webkit-linear-gradient(hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#A6E7FF", endColorstr="#FFFFFF", GradientType=1);
   width: 80%;
   margin: 0 auto;
   min-height: 100vh;
   padding: 2%;
   background-attachment: fixed;
}

.Jam-Title {
   display: flex;
   box-shadow: 4px 3px 8px;
   border-radius: 15px;
   height: 8em;
   justify-content: center;
   align-items: center;
   background-color: white;
}