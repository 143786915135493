.card {
   width: 55%;
   margin: 10% auto 5% auto;
   box-shadow: 4px 3px 8px;
   min-width: 200px;
   padding: 10px;
}

.btn {
   width: 15%;
}

.Home {
   width: 80%;
   background-attachment: fixed;
}

.Home-Title {
   display: flex;
   box-shadow: 4px 3px 8px;
   border-radius: 15px;
   height: 8em;
   justify-content: center;
   align-items: center;
   background-color: white;
}

.Home-Div {
   width: 80%;
   min-height: 100vh;
   margin: 0 auto;
   background: hsla(196, 100%, 83%, 1);

   background: linear-gradient( hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   background: -moz-linear-gradient(hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   background: -webkit-linear-gradient(hsla(196, 100%, 83%, 1) 0%, hsla(0, 0%, 100%, 1) 46%, hsla(41, 100%, 22%, 1) 100%);

   filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#A6E7FF", endColorstr="#FFFFFF", GradientType=1);
   padding: 2%;
   background-attachment: fixed;
}

.Home-P {
   font-size: 1.5vw;
}
